<template>
    <div>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="查询时间段" prop="timeRange">
                <el-date-picker
                    type="datetimerange"
                    v-model="queryModel.timeRange"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    format='yyyy-MM-dd HH:mm:ss'
                    value-format="yyyy-MM-dd HH:mm:ss"           
                    >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div class="custom-layout">
            <div class="custom-center">                
                <el-amap
                    ref="vehicleHistoryMap"
                    vid="vehicleHistoryMap"
                    :center="pointPosition"
                    :zoom="15"
                    style="width:100%;height:400px;"
                >
                    <!--数据点-->
                    <el-amap-marker :zIndex="1"
                        v-for="(item,index) in itemList"
                        :key="index"
                        :position="item.position"
                        :title="item.title"
                        >
                    </el-amap-marker>
                    <!--轨迹-->
                    <el-amap-polyline :path="mapPath"
                                    :zIndex="2"
                                    :isOutline="true"
                                    :outlineColor="'#ffeeff'"
                                    :borderWeight="3"
                                    :strokeColor="'#3366FF'"
                                    :strokeOpacity="1"
                                    :strokeWeight="6"
                                    :strokeStyle="'solid'"
                                    :strokeDasharray="[10, 5]"
                                    :lineJoin="'round'"
                                    :lineCap="'round'"
                    ></el-amap-polyline>
                </el-amap>                
            </div>
            <div class="custom-right">
                <el-table
                    ref="formTable"
                    :data="tableData"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    stripe
                    height="400"
                    @row-click="rowClick"
                >
                    <el-table-column label="经纬度" width="200px">
                        <template slot-scope="{row}">
                            <span style="cursor:pointer;">{{row.longitude}},{{row.latitude}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" width="160px" label="记录时间"></el-table-column>
                </el-table>
            </div>
        </div>
        <div style="text-align:right;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import vehicleApi from '@/api/base/personHealthLedger';

export default {
    name:"busVehicleList",
    props: ["personId"],
    data(){
        return{
            queryModel: {
                timeRange: null
            },
            loading: false,
            tableData: [],
            statusData:[],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 20,
            totalElements: 0,
            pageSizeList: [20,50,100],
            pointPosition: [112.240222, 30.337053],
            mapPath: [],
            itemList: []
        }
    },
    methods: {
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("personId", this.personId);

            if(this.queryModel.timeRange!=null){
                formData.append("startTime", this.queryModel.timeRange[0]);
                formData.append("endTime", this.queryModel.timeRange[1]);
            }

            vehicleApi.gpsHistoryList(formData).then((response)=>{
                this.loading = false;
                var jsonData = response.data;

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;
                this.mapPath = [];

                if(this.tableData.length>0){
                    var lngSum = this.tableData.reduce((acc,cur)=>{
                        return acc + parseFloat(cur.longitude);
                    },0);
                    
                    var lngAvg = lngSum / this.tableData.length;

                    console.log(lngAvg);

                    this.tableData.forEach(item=>{
                        var pos = [parseFloat(item.longitude),parseFloat(item.latitude)];
                        
                        //如果有数据点精度与平均值偏差0.5，则不显示该点
                        if(Math.abs(lngAvg - pos[0])<0.5){
                            this.mapPath.push(pos);
                        }
                    });

                    var firstRow = this.tableData[0];
                    this.rowClick(firstRow);
                }

                this.pageIndex = pageIndex;
            })
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.changePage(1);
        },
        rowClick(row, column, event) {
            this.itemList = [];
            
            this.pointPosition = [parseFloat(row.longitude),parseFloat(row.latitude)];

            this.itemList.push({
                position: [row.longitude,row.latitude],
                title: row.createTime
            });
        },
        //初始化日期
        getCurrentMonthFirst() {
            var self = this;
            var date = new Date();
            var startDate = new Date();
            var month = parseInt(date.getMonth() + 1);

            //当前测温记录

            var startMonth = month;
            // if (startDate.getDate() < 7) {
            //   startMonth = startDate.getMonth();
            //   startDate.setDate(0);
            // }

            // startDate = startDate.getDate() - 7;

            var startTime =
                date.getFullYear() + "-" + startMonth + "-" + startDate.getDate() + " 00:00:00";

            var endTime =
                date.getFullYear() + "-" + month + "-" + date.getDate() + " 23:59:59";

            self.queryModel.timeRange = [startTime, endTime];
        }
    },
    components: {

    },
    mounted() {
        //this.changePage(1);
    },
    created() {

    }
}
</script>
<style lang="scss" scoped>
.el-divider {
  margin: 5px 0;
}

.route-list{
    text-align: left;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.custom-layout{
    display: flex;
    flex-direction: row;
}

.custom-center{
    flex:1;
}

.custom-right{
    width: 400px;
}
</style>